exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-books-index-tsx": () => import("./../../../src/pages/books/index.tsx" /* webpackChunkName: "component---src-pages-books-index-tsx" */),
  "component---src-pages-books-sanity-product-slug-tsx": () => import("./../../../src/pages/books/{sanityProduct.slug}.tsx" /* webpackChunkName: "component---src-pages-books-sanity-product-slug-tsx" */),
  "component---src-pages-occasions-index-tsx": () => import("./../../../src/pages/occasions/index.tsx" /* webpackChunkName: "component---src-pages-occasions-index-tsx" */),
  "component---src-pages-occasions-sanity-event-slug-tsx": () => import("./../../../src/pages/occasions/{sanityEvent.slug}.tsx" /* webpackChunkName: "component---src-pages-occasions-sanity-event-slug-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

